import React, { Suspense, lazy } from 'react';
import {
  HashRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { isLoggedIn } from './Helpers';

const Website = lazy(() => import("./app/landing/Website"));
const Login = lazy(() => import('./app/auth/Login'));
const Register = lazy(() => import('./app/auth/Register'));
const DashboardLayout = lazy(() => import('./app/layouts/DashboardLayout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const Routes = () => (
  <HashRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={'/'} component={Website} />
        <GuestRoute exact path={'/login'} component={Login} />
        <GuestRoute exact path={'/register'} component={Register} />
        <PrivateRoute strict path={'/'} component={DashboardLayout} />
      </Switch>
    </Suspense>
  </HashRouter>
);

export default React.memo(Routes);
